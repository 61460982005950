<template>
    <b-modal v-model="exibe"
             hide-footer centered
             :title="id ? 'Editar Perfil' : 'Novo Perfil'"
             size="lg"
             no-enforce-focus>

        <template>
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-12 w-50">
                            <label class="mb-0">Título:</label>
                            <input type="text" class="modal-mensagens-email-input" v-model="titulo">
                        </div>
                    </div>

                    <div class="row" style="margin-top: 10px;">
                        <div class="col">
                            <label class="mb-0">Por Estado:</label>
                            <select 
                                class="form-select select_item_lista"
                                aria-label="multiple select example" 
                                multiple 
                                v-model="estadosSelecionados"
                            >
                                <option v-for="estado in lista_estados" :value="estado">{{ estado }}</option>
                            </select>
                        </div>
                        <div class="col">
                            <label class="mb-0">Por Cidade:</label>
                            <select 
                                class="form-select select_item_lista" 
                                aria-label="multiple select example"
                                multiple 
                                v-model="cidadesSelecionadas"
                            >
                                <option :value="cidade" v-for="cidade in lista_cidades">{{ cidade }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-12 text-right">
                            <botao-modal-cancel 
                                type="button" 
                                @click="fecharModal()">
                                Cancelar
                            </botao-modal-cancel>
                            <botao-modal-ok 
                                class="ml-2" 
                                @click="salvarPerfilCampanha()">
                                Confirmar
                            </botao-modal-ok>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">

            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import CampanhaServices from '@/assets/js/services/CampanhasServices'

    export default {
        name: 'ModalPerfilCampanha',
        components: {
            BotaoModalOk,
            BotaoModalCancel,
            CampanhaServices
        },
        props: {
            perfil: Object,
            tipo: String,
        },
        data: function () {
            return {
                exibe: false,
                id: '',
                titulo: '',
                lista_estados: [],
                lista_cidades: [],
                estadosSelecionados: [],
                cidadesSelecionadas: [],
            }
        },
        methods: {
            show() {
                this.limparCampos()

                if (this.perfil) {
                    this.id = this.perfil.Id;
                    this.titulo = this.perfil.Titulo;
                    
                    const { estados = [], cidades = [] } = JSON.parse(this.perfil?.CodigoExterno ?? '{}')
                    this.estadosSelecionados = estados;
                    this.cidadesSelecionadas = cidades;
                }

                this.exibe = true
            },
            async buscarFiltrosClientes() {
                const { Estados, Cidades } = await CampanhaServices.buscarFiltrosClientesParaCampanhas()
                
                this.lista_estados = Estados ?? []
                this.lista_cidades = Cidades ?? []
            },
            async salvarPerfilCampanha() {
                if (!this.titulo) {
                    alert('Título é necessário ser preenchido')
                    return
                }

                $("#caixa_carregamento").show()

                const Filtros = {
                    cidades: this.cidadesSelecionadas,
                    estados: this.estadosSelecionados
                }

                // Remove filtro de array vazios
                Object
                    .entries(Filtros)
                    .forEach(([key, array]) =>  { if (!array.length) delete Filtros[key] })

                const perfil = {
                    Titulo: this.titulo,
                    Tipo: this.tipo,
                    Filtros,
                }

                this.exibe = false;

                const perfilId = this.id;
                if (perfilId) {
                    await CampanhaServices.atualizarPerfilCampanha({ ...perfil, Id: perfilId });
                } else {
                    await CampanhaServices.criarPerfilCampanha(perfil);
                }

                this.$emit("salvo");
                $("#caixa_carregamento").hide()
            },
            fecharModal() {
                this.limparCampos();
                this.exibe = false;
            },
            limparCampos() {
                this.id = ''
                this.titulo = ''
                this.estadosSelecionados = []
                this.cidadesSelecionadas = []
            },
        },
        created() {
            this.buscarFiltrosClientes();
        },
    }
</script>

<style>
    .modal-mensagens-email-input {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }

    #titulo_modal_campanha {
        font-size: 15px;
        font-family: verdana;
        font-weight: bold;
    }

    .select_item_lista {
        width: 100%;
        background-color: var(--cinza-2);
        border-color: rgba(0, 0, 0, 15%);
    }

    .bnt_limpa_select {
        float: right;
        background-color: #259cd3;
        display: block;
        color: #FFF;
        padding: 0px 8px;
        cursor: pointer;
    }
</style>