import axios from 'axios'
const URL_API = '/api'

const buscarMensagens = async (tipo) => {
    try {
        const { data } = await axios.get(`${URL_API}/campanhas/mensagens/${tipo}`)
        return data
    } 
    catch (e) {
        console.log('erro buscar mensagens campanhas', e)
        throw (e)
    }
}
const buscarPerfisFiltrosCampanhas = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/campanhas/perfis-filtragem`)
        return data
    } catch (e) {
        console.log('erro ao buscar perfis de filtros de campanhas', e)
        throw (e)
    }
}
const buscarFiltrosClientesParaCampanhas = async () => {
    try {
        const { data } = await axios.get(`${URL_API}/campanhas/filtros-clientes`)
        return data
    } catch (e) {
        console.log('erro ao buscar filtros de clientes para campanhas', e)
        throw (e)
    }
}
const buscarListaClientesDoPerfil = async (campanhaId, formaDeContato,pageCount,pageLimit) => {
	try {
		const { data } = await axios.get(`${URL_API}/campanhas/lista-clientes${campanhaId ? `/${campanhaId}` : ""}`, formaDeContato ? {
			params: { formaDeContato: formaDeContato ,pageCount: pageCount,pageLimit: pageLimit}
		} : {params: {pageCount: pageCount,pageLimit: pageLimit}});
		return data;
	} catch (e) {
		console.log('erro ao buscar lista de clientes para perfil de campanhas', e);
		throw (e);
	}
}


const criarPerfilCampanha = async (perfil) => {
    try {
        const { data } = await axios.post(`${URL_API}/campanhas/perfil`, perfil)
        return data
    } catch (e) {
        console.log('erro ao criar perfil de campanha', e)
        throw (e)
    }
}
const criarMensagemCampanha = async (mensagemCampanha) => {
    try {
        const { data } = await axios.post(`${URL_API}/campanhas/mensagem`, mensagemCampanha)
        return data
    } catch (e) {
        console.log('erro ao criar mensagem de campanha')
        throw (e)
    }
}
const atualizarPerfilCampanha = async (perfil) => {
    try {
        const { data } = await axios.put(`${URL_API}/campanhas/perfil/${perfil.Id}`, perfil)
        return data
    } catch (e) {
        console.log('erro salvar atualizar perfil de campanha', e)
        throw (e)
    }
}
const atualizarMensagemCampanha = async (mensagemCampanha) => {
    try {
        const { data } = await axios.put(`${URL_API}/campanhas/mensagem/${mensagemCampanha.Id}`, mensagemCampanha)
        return data
    } catch (e) {
        console.log('erro ao atualizar mensagem de campanha')
        throw (e)
    }
}
const removerPerfilCampanha = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/campanhas/perfil/` + id)
        return data
    } catch (e) {
        console.log('erro deletar perfil de campanha', e)
        throw (e)
    }
}
const removerMensagemCampanha = async (id) => {
    try {
        const { data } = await axios.delete(`${URL_API}/campanhas/mensagem/` + id)
        return data
    } catch (e) {
        console.log('erro deletar mensagem de campanha', e)
        throw (e)
    }
}

const transmitirCampanha = async (dados) => {
    try {
        const { data } = await axios.post(`${URL_API}/campanhas/transmitirCampanha`, dados)
        return data
    } catch (e) {
        console.log('erro ao transmitir email', e)
        throw (e)
    }
}

export default {
    buscarMensagens,
    buscarPerfisFiltrosCampanhas,
    buscarFiltrosClientesParaCampanhas,
    buscarListaClientesDoPerfil,
    criarPerfilCampanha,
    criarMensagemCampanha,
    atualizarPerfilCampanha,
    atualizarMensagemCampanha,
    removerPerfilCampanha,
    removerMensagemCampanha,
    transmitirCampanha
}